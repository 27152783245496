<template>
  <div style="width:100%;height:100%;">
    <div
      style="display:flex;justify-content:center;flex-direction:column;align-item:center;padding-top:40%;"
    >
      <van-icon name="checked" color="#0389f7" size="80px" style="text-align:center;" />
      <h4 style="text-align:center;margin:0.2rem;font-size:16px;">信息提交成功</h4>
      <p
        style="text-align:center;margin:0 auto;width:54%;color:#a9a9a9;font-size:14px;"
      >您的投诉建议已提交至西安市应急管理局，感谢您的参与</p>
      <van-button style="width:50%;margin:30px auto 0;" type="info" round @click="closePage">关闭</van-button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closePage () {
      this.$router.replace({ path: '/' })
    }
  }
}
</script>
